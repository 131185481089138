import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { PhoneIcon, HomeIcon, SearchIcon } from "../components/Icons"
import BreakpointDown from "../components/Media/BreakpointDown"
import BreakpointUp from "../components/Media/BreakpointUp"
import Logo from "../images/logo.svg"

import CarportsDropdown from "../components/Header/DropDown/carports"
import GaragesDropdown from "../components/Header/DropDown/garages"
import AgricultureBuildingsDropdown from "../components/Header/DropDown/agriculture-buildings"
import PopularSizesDropdown from "../components/Header/DropDown/popular-sizes"
import CommercialDropdown from "../components/Header/DropDown/commercial"
import FinancingRtoDropdown from "../components/Header/DropDown/financing-rto"
import KnowledgeHubDropdown from "../components/Header/DropDown/knowledge-hub"
import AboutDropdown from "../components/Header/DropDown/about"
import Markquee from "../components/Marquee"
import PrimaryButton from "../components/Button/PrimaryButton"
import DefaultButton from "../components/Button/DefaultButton"
import CartButton from "../components/Header/CartButton"
import SearchBar from "./Header/SearchBar/SearchBar"

const _ = require("lodash")

const HeaderWrapper = styled.header`
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.16);
  height: 54px;
  ${BreakpointUp.xl`
    height:120px;
  `}
`
const HeaderRow = styled.div`
  position: relative;
  height: 120px;
`

const Navbar = styled.div`
  width: calc(100% - 200px);
  height: 120px;
  display: block;
  float: right;
`
const Nav = styled.nav`
  display: block;
  width: 100%;
  z-index: 1;

  ${BreakpointDown.xl`  
    justify-content: initial;
    padding: 20px 0;      
    max-width: 320px;
    width:100%;
    position: absolute;
    z-index: 99;
    transition: all 0.3s ease 0s;
    transform: translate3d(320px,0,0);
    overflow-y: auto;
    right: -40px;
    background-color:#fff;
    top: 54px;
    height: calc(100vh - 50px);
    box-shadow: 0 3px 26px rgba(0,0,0,0.2);
    align-items: flex-start;
  `}
`

const NavBrand = styled(Link)`
  overflow: hidden;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -30px;
  border: 15px solid rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  height: 125px;
  width: 125px;
  padding: 10px;
  outline: none;
  box-sizing: border-box;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #fff;
  }
  & img {
    margin: 0;
    display: block;
    height: 80px;
    position: relative;
  }
  ${BreakpointUp.xl`
    height: 180px;
    width: 180px;
    border: 20px solid rgba(0,0,0,0.15);
    padding:0;
  `}
`

const TopNav = styled.div`
  width: 100%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 7px 50px 7px 0;

  ${BreakpointUp.xl`    
    padding: 10px 15px 10px 0;
  `}
  > a, button {
    margin: 0 5px;
    @media (min-width: 1600px) {
      margin: 0 10px;
    }
    &.isDesktop {
      ${BreakpointDown.xl`
        display:none;
      `}
    }
  }
  .btn-sm {
    &.design-building {
      width: 235px;
      ${BreakpointDown.xl`
        width: auto;
      `}
    }
    &.phone-number {
      width: 192px;
      ${BreakpointDown.xl`
        width: auto;
      `}
    }
    .btn {
      width: 100%;

      ${BreakpointDown.xl`
        padding: 4px 8px;
      `}
      .text {
        ${BreakpointDown.xl`
          display:none;
        `}
      }
    }
  }

  & .cart-btn {
    display: block;

    span {
      display: block;
    }
  }
  & .hamburger {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 10px;
    right: 0;
    transition-duration: 0.5s;
    display: none;
    ${BreakpointDown.xl`
      display: inline-flex;      
    `}
    .icon {
      transition-duration: 0.5s;
      position: absolute;
      height: 4px;
      width: 30px;
      top: 15px;
      background-color: #212121;

      &:before {
        transition-duration: 0.5s;
        position: absolute;
        width: 30px;
        height: 4px;
        background-color: #212121;
        content: "";
        top: -10px;
        left: 0;
      }
      &:after {
        transition-duration: 0.5s;
        position: absolute;
        width: 30px;
        height: 4px;
        background-color: #212121;
        content: "";
        top: 10px;
        left: 0;
      }
    }
  }
`

const NavbarNav = styled.div`
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  ${BreakpointDown.xl`
    flex-direction:column;
    margin:0;
    width:100%;
    flex: auto;
    justify-content: inherit;
  `}
`
const NavItem = styled.div`
  margin: 0;
  .dropdown {
    ${BreakpointUp.xl`      
      display:block;
      visibility: hidden;
      opacity: 0;
      transform: scale(0.8) translateY(-12%);
      transition: 0.4s cubic-bezier(0.3, 0, 0, 1.3);
    `}
    &.dropdown-megamenu {
      ${BreakpointUp.xl` 
        transform: scale(0.8) translateY(-12%) translateX(-50%);
      `}
    }
  }
  &:hover {
    cursor: pointer;
    .dropdown {
      ${BreakpointUp.xl`
        display: block;
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
        transform: none;
      `}
      &.dropdown-megamenu {
        ${BreakpointUp.xl`
          transform:translateX(-50%);

        `}
      }
    }
  }
  ${BreakpointDown.xl`        
    width:100%;
  `}
  &.active {
    & .nav-link {
      color: #211f28;
      background-color: #f9f9f9;
    }
  }
  &:last-child {
    .dropdown-menu {
      ${BreakpointUp.xl`
        right:0;
        left:auto;
      `}
    }
  }
`

const NavLink = styled.span`
  display: block;
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  color: #333;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 30px 10px 15px;
  position: relative;
  .caret {
    display: block;
    transition: all 0.3s ease 0s;
    width: 2px;
    height: 8px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%) rotate(0deg);
    background-color: #fc0002;
    &:after {
      background: #fc0002;
      content: "";
      position: absolute;
      width: 8px;
      height: 2px;
      left: -3px;
      top: 3px;
    }
  }
  > a {
    color: #333;
  }
    
  &:hover {
    color: #000;
    > svg {
      fill: #000;
    }
    .caret {
      transform: translateY(-50%) rotate(45deg);
    }
    > a {
      color: #000;
    }
  }
`
const DropDown = styled.div`
  display: ${props => props.display};
`
const TopNavGroup = styled.div`
  border: 1px solid #afcfe5;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 5px;
  display: none;
  ${BreakpointUp.xl`
  display:block;
  height: 32px;
  `}
  @media (min-width: 1600px) {
    margin-right: 10px;
  }
  a {
    font-size: 12px;
    font-weight: 500;
    height: 32px;
    color: #666;
    padding: 7px 12px;

    &:hover {
      color: #000;
    }
    + a {
      border-left: 1px solid #afcfe5;
    }
  }
`
const NavSearch = styled.div`
  margin-right: 10px;
  height: 48px;

  display: none;
  ${BreakpointUp.xl`
    display:block;
  `}
`
const NavSearchIcon = styled(Link)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${BreakpointUp.xl`
    display:none;
  `}
  .icon {
    width: 28px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenu: true,
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleCommercial: false,
      AgricultureBuildings: false,
      isVisiblePopularSizes: false,
      isVisibleFinanceRto: false,
      isVisibleKnowledgeHub: false,
      isVisibleAbout: false,
      isVisibleLanguage: false,
    }
    this.toggleActive = this.toggleActive.bind(this)
  }
  componentDidMount() {
    this.navbar = document.getElementById("navbar");
    document.addEventListener("mousedown", this.handleClickOutside)
  }
  componentWillUnmount() {
    document.body.classList.remove("menu-open")
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  handleClickOutside = event => {
    if (this.navbar && !this.navbar.contains(event.target)) {
      this.setState({
        isVisibleCarports: false,
        isVisibleGarages: false,
        isVisibleCommercial: false,
        AgricultureBuildings: false,
        isVisiblePopularSizes: false,
        isVisibleFinanceRto: false,
        isVisibleKnowledgeHub: false,
        isVisibleAbout: false,
        isVisibleLanguage: false,
      })
    }
  }

  toggleActive() {
    this.setState({
      isMenu: !this.state.isMenu,
    })
    document.body.classList.toggle("menu-open", this.state.isMenu)
  }
  showCarportsDropDown() {
    let flag = !this.state.isVisibleCarports
    this.setState({
      isVisibleCarports: flag,
      isVisibleGarages: false,
      isVisibleCommercial: false,
      AgricultureBuildings: false,
      isVisiblePopularSizes: false,
      isVisibleFinanceRto: false,
      isVisibleKnowledgeHub: false,
      isVisibleAbout: false,
      isVisibleLanguage: false,
    })
  }
  showGaragesDropDown() {
    let flag = !this.state.isVisibleGarages
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: flag,
      isVisibleCommercial: false,
      AgricultureBuildings: false,
      isVisiblePopularSizes: false,
      isVisibleFinanceRto: false,
      isVisibleKnowledgeHub: false,
      isVisibleAbout: false,
      isVisibleLanguage: false,
    })
  }
  showCommercialDropDown() {
    let flag = !this.state.isVisibleCommercial
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleCommercial: flag,
      AgricultureBuildings: false,
      isVisiblePopularSizes: false,
      isVisibleFinanceRto: false,
      isVisibleKnowledgeHub: false,
      isVisibleAbout: false,
      isVisibleLanguage: false,
    })
  }
  showAgricultureBuildingsDropdown() {
    let flag = !this.state.AgricultureBuildings
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleCommercial: false,
      AgricultureBuildings: flag,
      isVisiblePopularSizes: false,
      isVisibleFinanceRto: false,
      isVisibleKnowledgeHub: false,
      isVisibleAbout: false,
      isVisibleLanguage: false,
    })
  }
  showPopularSizesDropDown() {
    let flag = !this.state.isVisibleAbout
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleCommercial: false,
      AgricultureBuildings: false,
      isVisiblePopularSizes: flag,
      isVisibleFinanceRto: false,
      isVisibleKnowledgeHub: false,
      isVisibleAbout: false,
      isVisibleLanguage: false,
    })
  }
  showFinancingRtoDropDown() {
    let flag = !this.state.isVisibleFinanceRto
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleCommercial: false,
      AgricultureBuildings: false,
      isVisiblePopularSizes: false,
      isVisibleFinanceRto: flag,
      isVisibleKnowledgeHub: false,
      isVisibleAbout: false,
      isVisibleLanguage: false,
    })
  }
  showKnowledgeHubDropdown() {
    let flag = !this.state.isVisibleKnowledgeHub
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleCommercial: false,
      AgricultureBuildings: false,
      isVisiblePopularSizes: false,
      isVisibleFinanceRto: false,
      isVisibleKnowledgeHub: flag,
      isVisibleAbout: false,
      isVisibleLanguage: false,
    })
  }
  showAboutDropDown() {
    let flag = !this.state.isVisibleAbout
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleCommercial: false,
      AgricultureBuildings: false,
      isVisiblePopularSizes: false,
      isVisibleFinanceRto: false,
      isVisibleKnowledgeHub: false,
      isVisibleAbout: flag,
      isVisibleLanguage: false,
    })
  }

  render() {
    const {
      isVisibleCarports,
      isVisibleGarages,
      isVisibleCommercial,
      AgricultureBuildings,
      isVisiblePopularSizes,
      isVisibleFinanceRto,
      isVisibleKnowledgeHub,
      isVisibleAbout,
    } = this.state
    const products = this.props.productData
    const location = this.props.location
    // const {products, location}  = this.props

    console.log("=============", isVisibleCarports)
    return (
      <>
        <HeaderWrapper>
          <div className="container">
            <HeaderRow>
              <NavBrand to="/">
                <img
                  src={Logo}
                  alt="Coast to Coast Carports, Steel Buildings Contractor and Supplier"
                  width="119"
                  height="80"
                  title="Coast-to-Coast-Carports-Metal-Buildings-Manufacturer"
                />
              </NavBrand>
              <Navbar>
                <TopNav>
                  {location.pathname !== "/search" && (
                    <NavSearch className="nav-search">
                      <SearchBar data={products} />
                    </NavSearch>
                  )}
                  <NavSearchIcon
                    to="/search"
                    className="nav-search-icon"
                    aria-label="mobile-search-icon"
                  >
                    <span className="icon">
                      <SearchIcon />
                    </span>
                  </NavSearchIcon>

                  <TopNavGroup>
                    <Link to="/become-a-dealer">Become A Dealer</Link>
                    <Link to="/become-an-installer">Become An Installer</Link>
                  </TopNavGroup>
                  <a
                    className="btn-sm design-building"
                    href="https://coasttocoastcarports.sensei3d.com/"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Home"
                  >
                    <PrimaryButton
                      icon={<HomeIcon />}
                      text="Design Your Building"
                    />
                  </a>
                  <a
                    href="tel:8666817846"
                    aria-label="(866) 681-7846"
                    className="btn-sm phone-number"
                  >
                    <DefaultButton icon={<PhoneIcon />} text="(866) 681-7846" />
                  </a>
                  <Link className="cart-btn" to="/cart">
                    <CartButton />
                  </Link>
                  <button
                    className="hamburger"
                    onClick={this.toggleActive}
                    aria-label="mobile menu"
                  >
                    {/* <HamburgerIcon /> */}
                    <div className="icon"></div>
                  </button>
                </TopNav>
                <Nav className="nav" id="navbar">
                  <NavbarNav>
                    <NavItem onClick={() => this.showCarportsDropDown()}>
                      <NavLink className="nav-link">
                        <Link to="/metal-carports">Carports</Link>
                        <span className="caret"></span>
                      </NavLink>
                      <DropDown
                        className="dropdown"
                        display={isVisibleCarports ? "block" : "none"}
                      >
                        <CarportsDropdown />
                      </DropDown>
                    </NavItem>
                    <NavItem onClick={() => this.showGaragesDropDown()}>
                      <NavLink className="nav-link">
                        <Link to="/metal-garages">Garages</Link>
                        <span className="caret"></span>
                      </NavLink>
                      <DropDown
                        className="dropdown"
                        display={isVisibleGarages ? "block" : "none"}
                      >
                        <GaragesDropdown />
                      </DropDown>
                    </NavItem>
                    <NavItem onClick={() => this.showCommercialDropDown()}>
                      <NavLink className="nav-link">
                        <Link to="/metal-buildings">Metal Buildings</Link>
                        <span className="caret"></span>
                      </NavLink>
                      <DropDown
                        className="dropdown"
                        display={isVisibleCommercial ? "block" : "none"}
                      >
                        <CommercialDropdown />
                      </DropDown>
                    </NavItem>
                    <NavItem
                      onClick={() => this.showAgricultureBuildingsDropdown()}
                    >
                      <NavLink className="nav-link">
                        <Link to="/metal-barns">Metal Barns</Link>
                        <span className="caret"></span>
                      </NavLink>
                      <DropDown
                        className="dropdown dropdown-megamenu"
                        display={AgricultureBuildings ? "block" : "none"}
                      >
                        <AgricultureBuildingsDropdown />
                      </DropDown>
                    </NavItem>
                    <NavItem onClick={() => this.showPopularSizesDropDown()}>
                      <NavLink className="nav-link">
                        Shop by Size <span className="caret"></span>
                      </NavLink>
                      <DropDown
                        className="dropdown dropdown-megamenu"
                        display={isVisiblePopularSizes ? "block" : "none"}
                      >
                        <PopularSizesDropdown />
                      </DropDown>
                    </NavItem>
                    <NavItem onClick={() => this.showFinancingRtoDropDown()}>
                      <NavLink className="nav-link">
                        RTO/Financing<span className="caret"></span>
                      </NavLink>
                      <DropDown
                        className="dropdown"
                        display={isVisibleFinanceRto ? "block" : "none"}
                      >
                        <FinancingRtoDropdown />
                      </DropDown>
                    </NavItem>
                    <NavItem onClick={() => this.showKnowledgeHubDropdown()}>
                      <NavLink className="nav-link">
                        Knowledge Hub<span className="caret"></span>
                      </NavLink>
                      <DropDown
                        className="dropdown"
                        display={isVisibleKnowledgeHub ? "block" : "none"}
                      >
                        <KnowledgeHubDropdown />
                      </DropDown>
                    </NavItem>
                    <NavItem onClick={() => this.showAboutDropDown()}>
                      <NavLink className="nav-link">
                        <Link to="/about-us">About Us</Link>
                        <span className="caret"></span>
                      </NavLink>
                      <DropDown
                        className="dropdown"
                        display={isVisibleAbout ? "block" : "none"}
                      >
                        <AboutDropdown />
                      </DropDown>
                    </NavItem>
                  </NavbarNav>
                </Nav>
              </Navbar>
            </HeaderRow>
          </div>
        </HeaderWrapper>
        <Markquee />
      </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
